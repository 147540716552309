/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// window.$ = window.jQuery = require("jquery");
window.jQuery = $;
window.$ = $;

import "../src/fontAwesomeCustom";

window.InfiniteScroll = require("infinite-scroll");

//https://github.com/tuupola/jquery_lazyload
window.JQueryLazy = require("lazyload");

window.WebFont = require("webfontloader");
window.qs = require("qs");
window.lightbox = require("lightbox2");
window.toastr = require("toastr");
window.toastr.options["positionClass"] = "toast-top-center";

import "bootstrap/js/dist/modal";
window.bootbox = require("bootbox");

window.Dropzone = require("dropzone");
Dropzone.autoDiscover = false;

window.Sortable = require("sortablejs/modular/sortable.core.esm.js").default;
window.Diff = require("diff");

import "jquery-bar-rating"; //STAR rating plugin

import iziModal from "izimodal/js/iziModal";
// Initialise imported function as jQuery function
window.$.fn.iziModal = iziModal;

import { dom } from "../src/fortawesome/fontawesome-svg-core";
window.fontAwesomeDom = dom;

//STYLESHEETS

import "izimodal/css/iziModal.css";
import "lightbox2/src/css/lightbox.css";
import "toastr/toastr.scss";
import "../src/sass-styles/bootstrap.scss";
import "dropzone/dist/dropzone.css";
import "dropzone/dist/basic.css";
