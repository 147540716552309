//import fontawesome from "./fortawesome/fontawesome";
//fontawesome.config.searchPseudoElements = true;
// we import only incons we need cuz otherwise file has 0.5MB
// import faPhone from "./fortawesome/fontawesome-pro/js/solid/faPhone";
// import faMapMarkerAlt from "./fortawesome/fontawesome-pro/js/solid/faMapMarkerAlt";
// import faStar from "./fortawesome/fontawesome-pro/js/solid/faStar";
// import faCheck from "./fortawesome/fontawesome-pro/js/solid/faCheck";
// import faEnvelope from "./fortawesome/fontawesome-pro/js/solid/faEnvelope";
// import faListUl from "./fortawesome/fontawesome-pro/js/solid/faListUl";
// import faMonument from "./fortawesome/fontawesome-pro/js/solid";
// import faFacebookF from "./fortawesome/fontawesome-free-brands/faFacebookF";
//
// import faLocationArrow from "./fortawesome/fontawesome-pro/js/regular/faLocationArrow";
// import faPlus from "./fortawesome/fontawesome-pro/js/regular/faPlus";
// import faMinus from "./fortawesome/fontawesome-pro/js/regular/faMinus";
// import faSearch from "./fortawesome/fontawesome-pro/js/regular/faSearch";
// import faSpinnerThird from "./fortawesome/fontawesome-pro/js/regular/faSpinnerThird";

import { library, dom } from "./fortawesome/fontawesome-svg-core";

import { faFacebookF } from "./fortawesome/free-brands-svg-icons/faFacebookF";
import { faGoogle } from "./fortawesome/free-brands-svg-icons/faGoogle";

import { faPhone } from "./fortawesome/pro-solid-svg-icons/faPhone";
import { faHome } from "./fortawesome/pro-solid-svg-icons/faHome";
import { faUser } from "./fortawesome/pro-solid-svg-icons/faUser";
import { faCaretDown } from "./fortawesome/pro-solid-svg-icons/faCaretDown";
import { faMapMarkerAlt } from "./fortawesome/pro-solid-svg-icons/faMapMarkerAlt";
import { faStar } from "./fortawesome/pro-solid-svg-icons/faStar";
import { faCheck } from "./fortawesome/pro-solid-svg-icons/faCheck";
import { faEnvelope } from "./fortawesome/pro-solid-svg-icons/faEnvelope";
import { faMonument } from "./fortawesome/pro-solid-svg-icons/faMonument";
import { faMapSigns } from "./fortawesome/pro-solid-svg-icons/faMapSigns";
import { faStarHalfAlt } from "./fortawesome/pro-solid-svg-icons/faStarHalfAlt";
import { faThLarge } from "./fortawesome/pro-solid-svg-icons/faThLarge";
import { faChevronCircleUp } from "./fortawesome/pro-solid-svg-icons/faChevronCircleUp";
import { faTicketAlt } from "./fortawesome/pro-solid-svg-icons/faTicketAlt";
import { faMapPin } from "./fortawesome/pro-solid-svg-icons/faMapPin";
import { faBold } from "./fortawesome/pro-solid-svg-icons/faBold";
import { faItalic } from "./fortawesome/pro-solid-svg-icons/faItalic";
import { faListUl } from "./fortawesome/pro-solid-svg-icons/faListUl";
import { faLink } from "./fortawesome/pro-solid-svg-icons/faLink";
import { faQuestionCircle } from "./fortawesome/pro-solid-svg-icons/faQuestionCircle";
import { faEye } from "./fortawesome/pro-solid-svg-icons/faEye";
import { faImages } from "./fortawesome/pro-solid-svg-icons/faImages";
import { faHeart as faHeartSolid } from "./fortawesome/pro-solid-svg-icons/faHeart";
import { faComment } from "./fortawesome/pro-solid-svg-icons/faComment";
import { faTrash } from "./fortawesome/pro-solid-svg-icons/faTrash";
import { faSyncAlt } from "./fortawesome/pro-solid-svg-icons/faSyncAlt";
import { faWatch } from "./fortawesome/pro-solid-svg-icons/faWatch";
import { faWalking as faWalkingSolid } from "./fortawesome/pro-solid-svg-icons/faWalking";
import { faBiking as faBikingSolid } from "./fortawesome/pro-solid-svg-icons/faBiking";
import { faMountains as faMountainsSolid } from "./fortawesome/pro-solid-svg-icons/faMountains";
import { faHighlighter } from "./fortawesome/pro-solid-svg-icons/faHighlighter";

import { faChild } from "./fortawesome/pro-solid-svg-icons/faChild";
import { faWheelchair } from "./fortawesome/pro-solid-svg-icons/faWheelchair";
import { faPiggyBank } from "./fortawesome/pro-solid-svg-icons/faPiggyBank";
import { faCloudSunRain } from "./fortawesome/pro-solid-svg-icons/faCloudSunRain";
import { faDogLeashed } from "./fortawesome/pro-solid-svg-icons/faDogLeashed";
import { faHeadSideBrain } from "./fortawesome/pro-solid-svg-icons/faHeadSideBrain";

import { faTimes } from "./fortawesome/pro-solid-svg-icons/faTimes";
import { faPlus } from "./fortawesome/pro-regular-svg-icons/faPlus";
import { faCheckDouble } from "./fortawesome/pro-regular-svg-icons/faCheckDouble";
import { faMinus } from "./fortawesome/pro-regular-svg-icons/faMinus";
import { faSearch } from "./fortawesome/pro-regular-svg-icons/faSearch";
import { faLocationArrow } from "./fortawesome/pro-regular-svg-icons/faLocationArrow";
import { faSpinnerThird } from "./fortawesome/pro-regular-svg-icons/faSpinnerThird";
import { faSlidersH } from "./fortawesome/pro-regular-svg-icons/faSlidersH";
import { faBrowser } from "./fortawesome/pro-regular-svg-icons/faBrowser";
import { faClock } from "./fortawesome/pro-regular-svg-icons/faClock";
import { faSignOut } from "./fortawesome/pro-regular-svg-icons/faSignOut";
import { faHeart as faHeartRegular } from "./fortawesome/pro-regular-svg-icons/faHeart";
import { faArrowFromLeft as faArrowFromLeftRegular } from "./fortawesome/pro-regular-svg-icons/faArrowFromLeft";
import { faArrowToRight as faArrowToRightRegular } from "./fortawesome/pro-regular-svg-icons/faArrowToRight";
import { faEraser as faEraserRegular } from "./fortawesome/pro-regular-svg-icons/faEraser";
import { faSearchPlus as faSearchPlusRegular } from "./fortawesome/pro-regular-svg-icons/faSearchPlus";
import { faSearchMinus as faSearchMinusRegular } from "./fortawesome/pro-regular-svg-icons/faSearchMinus";
import { faMapMarkerSlash as faMapMarkerSlashRegular } from "./fortawesome/pro-regular-svg-icons/faMapMarkerSlash";

import { faMapMarkedAlt } from "./fortawesome/pro-light-svg-icons/faMapMarkedAlt";

import { faFolderOpen } from "./fortawesome/pro-duotone-svg-icons/faFolderOpen";
import { faCameraAlt } from "./fortawesome/pro-duotone-svg-icons/faCameraAlt";

library.add(
  faPhone,
  faHome,
  faUser,
  faCaretDown,
  faMapMarkedAlt,
  faStar,
  faCheck,
  faEnvelope,
  faThLarge,
  faMonument,
  faFacebookF,
  faGoogle,
  faComment,

  faCheckDouble,
  faTimes,
  faPlus,
  faMinus,
  faSearch,
  faLocationArrow,
  faSpinnerThird,
  faMapSigns,
  faChevronCircleUp,
  faSlidersH,
  faBrowser,
  faClock,
  faTicketAlt,
  faMapPin,
  faBold,
  faItalic,
  faListUl,
  faLink,
  faQuestionCircle,
  faEye,
  faStarHalfAlt,
  faImages,
  faHighlighter,

  faMapMarkerAlt,
  faSignOut,

  faFolderOpen,
  faCameraAlt,
  faHeartRegular,
  faHeartSolid,
  faTrash,
  faSyncAlt,

  faChild,
  faWheelchair,
  faPiggyBank,
  faCloudSunRain,
  faDogLeashed,
  faWatch,
  faWalkingSolid,
  faBikingSolid,
  faMountainsSolid,
  faHeadSideBrain,

  faArrowFromLeftRegular,
  faArrowToRightRegular,
  faEraserRegular,
  faSearchPlusRegular,
  faSearchMinusRegular,
  faMapMarkerSlashRegular
);

dom.watch();
